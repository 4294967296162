import InvolvementSquare from "@/components/help-center/custom-tags/InvolvementSquare.vue";

export const involvementSquare = {
  render: InvolvementSquare,
  attributes: {
    squareClasses: {
      type: String,
    },
    state: {
      type: String,
    },
  },
};
